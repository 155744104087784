import React, { useState, useRef, useMemo } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';

const ValentinesPage = () => {
  // State for tracking if the "Yes" button has been clicked.
  const [accepted, set_accepted] = useState(false);
  // State for the number of times the "No" button has been clicked.
  const [no_click_count, set_no_click_count] = useState(0);
  // State for controlling the No button’s position when it goes into "avoid mode."
  const [no_btn_position, set_no_btn_position] = useState({ x: 0, y: 0 });

  // Messages for the "No" button during the first five clicks.
  const no_messages = [
    'No',
    'Really? :(',
    'Try again',
    'Wrong choice',
    "Opposite of yippee..",
    "Try and catch me then!"
  ];

  // Ref to the "No" button so we can get its dimensions.
  const no_button_ref = useRef(null);

  // React Spring animation props for the "No" button when in avoid mode.
  const spring_props = useSpring({
    left: no_btn_position.x,
    top: no_btn_position.y,
    config: { tension: 300, friction: 20 }
  });

  // Handler for the "Yes" button click.
  const handle_yes_click = () => {
    set_accepted(true);
  };

  // Handler for the "No" button click.
  const handle_no_click = () => {
    if (no_click_count < 5) {
      set_no_click_count(no_click_count + 1);
    }
  };

  // When in avoid mode (no_click_count >= 5), relocate the button ensuring it stays fully visible.
  const handle_no_button_mouse_enter = () => {
    if (no_click_count >= 5 && no_button_ref.current) {

      const button_width = no_button_ref.current.offsetWidth;
      const button_height = no_button_ref.current.offsetHeight;
      const margin = 20; // Minimum space from each edge
      // Compute the available space within the viewport:
      const available_width = window.innerWidth - button_width - 2 * margin;
      const available_height = window.innerHeight - button_height - 2 * margin;
      const new_x = margin + Math.random() * available_width;
      const new_y = margin + Math.random() * available_height;
      set_no_btn_position({ x: new_x, y: new_y });

    }
  };


  // Generate hearts only once so they don't move on each render.
  const hearts = useMemo(() => {
    const hearts_arr = [];
    for (let i = 0; i < 20; i++) {
      const size = Math.random() * 50 + 20; // sizes between 20px and 70px
      const pink_value = Math.floor(Math.random() * 100 + 155);
      const left_percent = Math.random() * 100;
      const top_percent = Math.random() * 100;
      hearts_arr.push(
        <div
          key={i}
          className="heart"
          style={{
            position: 'absolute',
            width: size,
            height: size,
            left: `${left_percent}%`,
            top: `${top_percent}%`,
            color: `rgb(255, ${pink_value - 50}, ${pink_value})`,
            opacity: 0.7,
            fontSize: size,
            transform: `rotate(${Math.random() * 360}deg)`,
            pointerEvents: 'none'
          }}
        >
          ♥
        </div>
      );
    }
    return hearts_arr;
  }, []);

  // Generate multiple fireworks only once.
  const fireworks = useMemo(() => {
    const fireworks_arr = [];
    const colors = ['#ff4b5c', '#ffb3ba', '#ffdfba', '#ffffba', '#baffc9', '#bae1ff'];
    for (let i = 0; i < 7; i++) {
      const size = Math.random() * 50 + 30;
      const left = Math.random() * 100;
      const top = Math.random() * 100;
      const delay = Math.random() * 1.5;
      const color = colors[Math.floor(Math.random() * colors.length)];
      fireworks_arr.push(
        <div
          key={i}
          className="firework"
          style={{
            width: size,
            height: size,
            background: color,
            left: `${left}%`,
            top: `${top}%`,
            animationDelay: `${delay}s`
          }}
        ></div>
      );
    }
    return fireworks_arr;
  }, []);

  return (
    <div
      className={`valentine-page ${accepted ? 'accepted' : ''}`}
      style={{
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100dvh',
        maxHeight: '100dvh',
        backgroundColor: accepted ? '#000' : '#fff',
        transition: 'background-color 1s'
      }}
    >
      {/* Render static hearts */}
      {hearts}

      {/* Render a container of fireworks if accepted */}
      {accepted && (
        <div>
        <div className="firework"></div>
        <div className="firework"></div>
        <div className="firework"></div>
        <div className="firework"></div>
        <div className="firework"></div>
        <div className="firework"></div>
        <div className="firework"></div>
        </div>
        
      )}

      <Container
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: '100vh', position: 'relative', zIndex: 1 }}
      >
        <Row>
          <Col className="text-center">
            <h1
              style={{
                fontSize: '3rem',
                fontWeight: 'bold',
                color: accepted ? '#fff' : '#000',
                transition: 'color 1s'
              }}
            >
              {accepted
                ? 'Yippee! See you on the 14th :)'
                : 'Angie, will you be my valentine?'}
            </h1>
          </Col>
        </Row>
        {!accepted && 
        
        <Row className="mt-4">
        <Col xs="auto">
          <Button className="cute-button" variant="success" size="lg" onClick={handle_yes_click}>
            Yes
          </Button>
        </Col>
        <Col xs="auto" style={{ position: 'relative' }}>

        {no_click_count >= 5 ? (
          <animated.div
            style={{
              position: 'fixed', // Using fixed positioning so it's relative to the viewport
              ...spring_props,
            }}
            onMouseEnter={handle_no_button_mouse_enter}
            onTouchEnd ={(e) => {
              e.preventDefault();
              handle_no_button_mouse_enter();
            }}
            onClick={(e) => {
              e.preventDefault();
              handle_no_button_mouse_enter();
              // Prevent default click behavior on mobile
            }}
          >
            <Button
              ref={no_button_ref}
              className="cute-button"
              variant="danger"
              size="lg"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handle_no_click();
              }}
              onTouchStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handle_no_click();
              }}
            >
              {no_messages[Math.min(no_click_count, no_messages.length - 1)]}
            </Button>
          </animated.div>
        ) : (
          <Button
            ref={no_button_ref}
            className="cute-button"
            variant="danger"
            size="lg"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handle_no_click();
            }}
          >
            {no_messages[Math.min(no_click_count, no_messages.length - 1)]}
          </Button>
        )}

        </Col>
      </Row>
        }

      </Container>

      {/* Inline CSS styles */}
      <style jsx="true">{`
        .heart {
          /* Additional styling for hearts (if needed) */
        }

          @keyframes firework {
            0% { transform: translate(var(--x), var(--initialY)); width: var(--initialSize); opacity: 1; }
            50% { width: 0.5vmin; opacity: 1; }
            100% { width: var(--finalSize); opacity: 0; }
          }

          /* @keyframes fireworkPseudo {
            0% { transform: translate(-50%, -50%); width: var(--initialSize); opacity: 1; }
            50% { width: 0.5vmin; opacity: 1; }
            100% { width: var(--finalSize); opacity: 0; }
          }
          */
          .firework,
          .firework::before,
          .firework::after
          {
            --initialSize: 0.5vmin;
            --finalSize: 45vmin;
            --particleSize: 0.2vmin;
            --color1: yellow;
            --color2: khaki;
            --color3: white;
            --color4: lime;
            --color5: gold;
            --color6: mediumseagreen;
            --y: -30vmin;
            --x: -50%;
            --initialY: 60vmin;
            content: "";
            animation: firework 2s infinite;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, var(--y));
            width: var(--initialSize);
            aspect-ratio: 1;
            background: 
              /*
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 0%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 0%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 100%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 100%,
              */
              
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
              radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
              radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
              radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,
              
              /* bottom right */
              radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80% 90%,
              radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
              radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
              radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
              radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,
              
              /* bottom left */
              radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22% 90%,
              radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
              radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
              radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
              radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
              radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,
              
              /* top left */
              radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80% 10%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
              radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
              radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
              radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
              radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
              radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,
              
              /* top right */
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22% 14%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
              radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%
              ;
            background-size: var(--initialSize) var(--initialSize);
            background-repeat: no-repeat;
          }

          .firework::before {
            --x: -50%;
            --y: -50%;
            --initialY: -50%;
          /*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
            transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
          /*   animation: fireworkPseudo 2s infinite; */
          }

          .firework::after {
            --x: -50%;
            --y: -50%;
            --initialY: -50%;
          /*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
            transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
          /*   animation: fireworkPseudo 2s infinite; */
          }

          .firework:nth-child(2) {
            --x: 30vmin;
          }

          .firework:nth-child(2),
          .firework:nth-child(2)::before,
          .firework:nth-child(2)::after {
            --color1: pink;
            --color2: violet;
            --color3: fuchsia;
            --color4: orchid;
            --color5: plum;
            --color6: lavender;  
            --finalSize: 40vmin;
            left: 30%;
            top: 60%;
            animation-delay: -0.25s;
          }

          .firework:nth-child(3) {
            --x: -30vmin;
            --y: -50vmin;
          }

          .firework:nth-child(3),
          .firework:nth-child(3)::before,
          .firework:nth-child(3)::after {
            --color1: cyan;
            --color2: lightcyan;
            --color3: lightblue;
            --color4: PaleTurquoise;
            --color5: SkyBlue;
            --color6: lavender;
            --finalSize: 35vmin;
            left: 70%;
            top: 60%;
            animation-delay: -0.4s;
          }
        .cute-button {
          border-radius: 20px;
          font-size: 1.2rem;
          padding: 0.75rem 1.5rem;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s;
          -webkit-tap-highlight-color: transparent;
          user-select: none;
        }
        .cute-button:active {
          transform: none;
          outline: none;
        }
        .cute-button:hover {
          transform: scale(1.05);
        }
      `}</style>
    </div>
  );
};

export default ValentinesPage;
