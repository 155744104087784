import './App.scss';
import React, { useState, useEffect, useRef } from 'react'

import Header from './Components/header';
import Hero from './Components/hero';
import About from './Components/about';
import Projects from './Components/projects';
import Contact from './Components/contact';
import FileUploader from './Components/weddingpage';
import ValentinesPage from './Components/valentines';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { useSpring, animated as a, config } from "react-spring";

function App() {
  

  //#region Logic for header & bottom navigation
  const [typeWriterStatus, setTypeWriterStatus] = useState(true)


  const stickyBottomProps = useSpring({
    opacity: typeWriterStatus ? 0.5 : -3,
    marginBottom: typeWriterStatus ? "3vw" : "200vw",
    config: config.wobbly
  });
  //#endregion


  //#region Logic for about scrolling
  const aboutMeReference = useRef();
  const scrollToAboutMe = () => {
    if(aboutMeReference.current){
      const offsetBottom = aboutMeReference.current.offsetTop - 20;
      document.querySelector('.App').scroll({ top: offsetBottom, behavior: 'smooth'});
    }
  }
  
  const projectsReference = useRef();
  const scrolltoProjects = () => {
    if(projectsReference.current){
      const offsetBottom = projectsReference.current.offsetTop - 20;
      document.querySelector('.App').scroll({ top: offsetBottom, behavior: 'smooth'});
    } 
  }

  const contactReference = useRef();
  const scrolltoContact = () => {
    if(contactReference.current){
      const offsetBottom = contactReference.current.offsetTop - 20;
      document.querySelector('.App').scroll({ top: offsetBottom, behavior: 'smooth'});
    } 
  }
  
  //#endregion

  function HomePage(){
    return (
      <div className="App">
      <Header scrollToAboutMe={scrollToAboutMe} scrolltoProjects={scrolltoProjects} scrolltoContact={scrolltoContact}></Header>
      <div className='main'>
      
      <Hero></Hero>
  
        {/* About Section */}
  
        <div ref={aboutMeReference}>
          <About></About>
        </div>
  
        {/* Projects Section */}
  
        <div ref={projectsReference}>
          <Projects></Projects>
        </div>
  
        {/* Contact Section */}
        <div ref={contactReference}>
          <Contact></Contact>
        </div>
  
      </div>
  
      <a.div class="sticky_bottom" style={stickyBottomProps}><a href="#" onClick={scrolltoContact}>@</a></a.div>
      
  
      <div className="footer">Designed & Built by Ralph Roque</div>
  
    </div>
    )
  }
  
  
  //…rest of App.js below…

  
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={ <HomePage /> } />
          <Route path="/wedding" element={ <FileUploader />} />
          <Route path="/valentines" element={ <ValentinesPage />} />
        </Routes>
    </BrowserRouter>

  );
}


export default App;
